import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Alert, Row, Col, Spinner } from "react-bootstrap";
import { PartsContext } from "../../Context/PartsContext";
import { AuthContext } from "../../Context/AuthContext";
import BackButton from "../BackButton";
import axiosInstance from "../../axios";
import FileAttachments from "./FileAttachments";
import StepFileUploadSection from "./StepFileUploadSection";
import StepStatusSection from "./StepStatusSection";
import StepCommentSection from "./StepCommentSection";

function StepDetails() {
    const { partId, stepId } = useParams();
    const [stepLoading, setStepLoading] = useState(true);
    const [attachmentsLoading, setAttachmentsLoading] = useState(true);
    const { parts, setParts } = useContext(PartsContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [orderParts, setOrderParts] = useState([]);
    const [stepComments, setStepComments] = useState([]);

    const part = parts.find(p => p.id == partId);
    const step = part?.steps?.find(s => s.index == stepId);

    const isFirstInProgressStep = part?.steps?.findIndex((s) => s.status === "in progress") === part?.steps?.indexOf(step);

    useEffect(() => {
        const fetchPart = async () => {
            try {
                const response = await axiosInstance.get(`/part/${partId}`);
                setParts(prev => prev.map(p =>
                    p.id === response.data.id ? response.data : p
                ));
            } catch (error) {
                console.error("Failed to fetch part:", error);
            } finally {
                setStepLoading(false);
            }
        };

        fetchPart();
    }, [partId]);

    useEffect(() => {
        const fetchAttachments = async () => {
            if (!part || !step) return;
            try {
                const response = await axiosInstance.get(`/stepAttachments/${partId}/${step.index}`);
                const attachmentsWithMetadata = response.data.map(attachment => ({
                    ...attachment,
                    part_id: partId,
                    step_index: step.index
                }));
                setAttachments(attachmentsWithMetadata);
            } catch (error) {
                console.error("Failed to fetch attachments:", error);
            } finally {
                setAttachmentsLoading(false);
            }
        };

        fetchAttachments();
    }, [part, step]);

    useEffect(() => {
        const fetchOrderParts = async () => {
            if (!part) return;
            try {
                const response = await axiosInstance.get(`/order/${part.order_id}/parts`);
                setOrderParts(response.data);
            } catch (error) {
                console.error("Failed to fetch order parts:", error);
            }
        };

        fetchOrderParts();
    }, [part]);

    useEffect(() => {
        const fetchComments = async () => {
            if (!step?.id || !partId) return;

            try {
                const response = await axiosInstance.get(`/stepComments/${partId}/${part.workflow_id}/${step.id}`);
                setStepComments(response.data);
            } catch (error) {
                console.error("Failed to fetch comments:", error);
                setErrorMessage("Failed to load comments");
            }
        };

        fetchComments();
    }, [step, partId, part]);

    if (!part || !step) {
        return <div>Step not found</div>;
    }

    const getStepDisplayStatus = (step, steps, stepIndex) => {
        const hasRejectedSteps = steps.some(s => s.status === "rejected");
        const firstNonApprovedIndex = steps.findIndex(s => s.status !== "approved");

        if (step.status === "approved") return "approved";
        if (step.status === "rejected") return "rejected";

        if (hasRejectedSteps || (firstNonApprovedIndex !== -1 && stepIndex > firstNonApprovedIndex)) {
            return "not started";
        }

        return "in progress";
    };

    const getStatusIcon = () => {
        const displayStatus = getStepDisplayStatus(step, part.steps, part.steps.indexOf(step));

        if (displayStatus === "approved") {
            return (
                <div className="text-success">
                    Approved
                </div>
            );
        } else if (displayStatus === "rejected") {
            return (
                <div className="text-danger">
                    Rejected
                </div>
            );
        } else if (displayStatus === "not started") {
            return (
                <div className="text-secondary">
                    Not Started
                </div>
            );
        }
        return (
            <div className="text-secondary">
                In Progress
            </div>
        );
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    return (
        <>
            <Card style={{ position: 'relative' }}>
                {(stepLoading || attachmentsLoading) && (
                    <div style={overlayStyle}>
                        <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )}
                <Card.Body>
                    <Row className="align-items-center mb-4">
                        <Col xs="auto">
                            <BackButton />
                        </Col>
                        <Col>
                            <h4 className="mb-0">
                                {step.name}
                            </h4>
                        </Col>
                        <Col xs="auto">
                            {getStatusIcon()}
                        </Col>
                    </Row>

                    <StepStatusSection
                        step={step}
                        part={part}
                        partId={partId}
                        isFirstInProgressStep={isFirstInProgressStep}
                        setErrorMessage={setErrorMessage}
                        orderParts={orderParts}
                    />

                    <StepCommentSection
                        step={{
                            ...step,
                            part_id: partId,
                            workflow_id: part?.workflow_id
                        }}
                        comments={stepComments}
                        setComments={setStepComments}
                        setErrorMessage={setErrorMessage}
                    />

                    <StepFileUploadSection
                        partId={partId}
                        step={step}
                        setErrorMessage={setErrorMessage}
                        setAttachments={setAttachments}
                    />

                    {attachments.length > 0 && (
                        <div className="mt-3">
                            <h5>Attachments</h5>
                            <FileAttachments
                                files={attachments}
                                isUploaded={true}
                                setErrorMessage={setErrorMessage}
                                setAttachments={setAttachments}
                            />
                        </div>
                    )}

                    {errorMessage && (
                        <Alert variant="danger" className="mt-3">
                            {errorMessage}
                        </Alert>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default StepDetails;
