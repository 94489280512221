import React from 'react';
import { Card, Form, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PartsCardGrid = ({
    parts,
    templates,
    handleWorkflowTemplateChange,
    handleInstructionChange,
    handlePartChange,
    handleDeletePart
}) => {
    const groupedParts = parts.reduce((acc, part, index) => {
        const templateId = part.workflow_template_id;
        if (!acc[templateId]) {
            acc[templateId] = [];
        }
        acc[templateId].push({ ...part, originalIndex: index });
        return acc;
    }, {});

    // Base widths for common columns
    const columnWidths = {
        part: { minWidth: "150px" },
        quantity: { minWidth: "100px" },
        price: { minWidth: "80px" },
        dueDate: { minWidth: "130px" },
        workflow: { minWidth: "180px" },
        delete: { minWidth: "40px" }
    };

    const getInstructionWidth = (stepName) => ({
        minWidth: `${Math.max(Math.max(stepName.length - 15, 0) * 10 + 150, 200)}px`
    });

    return (
        <div>
            {Object.entries(groupedParts).map(([templateId, templateParts]) => {
                const template = templates.find(t => t.id === parseInt(templateId));

                return (
                    <Card key={templateId} className="mb-4">
                        <Card.Header className="bg-light py-2">
                            <h5 className="mb-0">{template?.name || 'Unknown Workflow'}</h5>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div style={{ overflowX: 'auto' }}>
                                <Table className="mb-0">
                                    <thead>
                                        <tr>
                                            <th style={columnWidths.part}>Part</th>
                                            <th style={columnWidths.quantity}>Quantity</th>
                                            <th style={columnWidths.price}>Price</th>
                                            <th style={columnWidths.dueDate}>Due Date</th>
                                            <th style={columnWidths.workflow}>Workflow</th>
                                            {template?.steps.map(step => (
                                                <th key={step.id} style={getInstructionWidth(step.name)}>{step.name}</th>
                                            ))}
                                            <th style={{ width: '100%' }}></th>
                                            <th style={columnWidths.delete}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templateParts.map((part) => (
                                            <tr key={part.originalIndex}>
                                                <td className="align-middle">
                                                    <div>
                                                        <div>{part.part_unique_id}</div>
                                                        <small className="text-muted">Rev: {part.revision}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={part.qty_ordered}
                                                        onChange={(e) => handlePartChange(part.originalIndex, 'qty_ordered', e.target.value)}
                                                        className={part.qty_ordered ? 'border-success bg-success bg-opacity-10' : ''}
                                                        size="sm"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={part.unit_price ? part.unit_price : ''}
                                                        onChange={(e) => handlePartChange(part.originalIndex, 'unit_price', e.target.value)}
                                                        className={part.unit_price ? 'border-success bg-success bg-opacity-10' : ''}
                                                        size="sm"
                                                        min="0"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="date"
                                                        value={part.due_date || ''}
                                                        onChange={(e) => handlePartChange(part.originalIndex, 'due_date', e.target.value)}
                                                        className={part.due_date ? 'border-success bg-success bg-opacity-10' : ''}
                                                        size="sm"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Select
                                                        value={part.workflow_template_id}
                                                        onChange={(e) => handleWorkflowTemplateChange(part.originalIndex, parseInt(e.target.value))}
                                                        className={part.workflow_template_id ? 'border-success bg-success bg-opacity-10' : ''}
                                                        size="sm"
                                                    >
                                                        {templates.map(template => (
                                                            <option key={template.id} value={template.id}>
                                                                {template.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </td>
                                                {template?.steps.map(step => {
                                                    const partStep = part.steps.find(s => s.id === step.id);
                                                    return (
                                                        <td key={step.id}>
                                                            <Form.Select
                                                                value={partStep?.instruction_id || ''}
                                                                onChange={(e) => {
                                                                    const selectedValue = e.target.value;
                                                                    if (selectedValue === 'skip') {
                                                                        handleInstructionChange(
                                                                            part.originalIndex,
                                                                            step.index,
                                                                            step.id,
                                                                            'skip',
                                                                            'skip'
                                                                        );
                                                                    } else {
                                                                        const selectedInstruction = step.available_instructions
                                                                            .find(i => i.id === parseInt(selectedValue));
                                                                        handleInstructionChange(
                                                                            part.originalIndex,
                                                                            step.index,
                                                                            step.id,
                                                                            selectedInstruction?.id || null,
                                                                            selectedInstruction?.description || ''
                                                                        );
                                                                    }
                                                                }}
                                                                className={partStep?.instruction ? 'border-success bg-success bg-opacity-10' : ''}
                                                                size="sm"
                                                            >
                                                                <option value="">Select instruction...</option>
                                                                {step.available_instructions?.map(instruction => (
                                                                    <option key={instruction.id} value={instruction.id}>
                                                                        {instruction.name}
                                                                    </option>
                                                                ))}
                                                                <option value="skip">Skip This Step</option>
                                                            </Form.Select>
                                                        </td>
                                                    );
                                                })}
                                                <td></td>
                                                <td className="text-center">
                                                    <Button
                                                        variant="outline-danger"
                                                        size="sm"
                                                        onClick={() => handleDeletePart(part.originalIndex)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                );
            })}
        </div>
    );
};

export default PartsCardGrid; 