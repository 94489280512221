import { useState, useContext } from 'react';
import { Container, Row, Col, Tabs, Tab, Alert, Card, Button } from 'react-bootstrap';
import { AuthContext } from './Context/AuthContext';
import UserManager from './UserManager';
import DepartmentManager from './DepartmentManager';
import WorkflowTemplateManager from './WorkflowTemplateManager';
import { useNavigate } from 'react-router-dom';

const ROLE_HIERARCHY = {
    owner: 3,
    admin: 2,
    user: 1
};

function Account() {
    const { username, role, logout } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [activeTab, setActiveTab] = useState('users');
    const [employees, setEmployees] = useState([]);
    const [shouldRefreshDepartments, setShouldRefreshDepartments] = useState(false);
    const navigate = useNavigate();

    const hasPermission = (requiredRole) => {
        return (ROLE_HIERARCHY[role] || 0) >= (ROLE_HIERARCHY[requiredRole] || 0);
    };

    const handleEmployeesUpdate = (newEmployees) => {
        setEmployees(newEmployees);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    if (!hasPermission('admin')) {
        return (
            <Container className="mt-4">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <h4 className="mb-0">Welcome, {username}</h4>
                                <Button variant="outline-primary" onClick={handleLogout}>
                                    Logout
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <h4 className="mb-0">Welcome, {username}</h4>
                            <Button variant="outline-primary" onClick={handleLogout}>
                                Logout
                            </Button>
                        </Card.Body>
                    </Card>

                    {error && (
                        <Alert variant="danger" dismissible onClose={() => setError(null)}>
                            {error}
                        </Alert>
                    )}
                    {successMessage && (
                        <Alert variant="success" dismissible onClose={() => setSuccessMessage(null)}>
                            {successMessage}
                        </Alert>
                    )}

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="users" title="Users">
                            <UserManager
                                setError={setError}
                                setSuccessMessage={setSuccessMessage}
                                onEmployeesUpdate={handleEmployeesUpdate}
                            />
                        </Tab>
                        <Tab eventKey="departments" title="Departments">
                            <DepartmentManager
                                setError={setError}
                                setSuccessMessage={setSuccessMessage}
                                employees={employees}
                                shouldRefresh={shouldRefreshDepartments}
                                onRefreshComplete={() => setShouldRefreshDepartments(false)}
                            />
                        </Tab>
                        <Tab eventKey="workflows" title="Workflow Templates">
                            <WorkflowTemplateManager
                                setError={setError}
                                setSuccessMessage={setSuccessMessage}
                                onWorkflowUpdate={() => setShouldRefreshDepartments(true)}
                                key={activeTab}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}

export default Account;