import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../axios";

const ROLE_HIERARCHY = {
    owner: 4,
    admin: 3,
    user: 2,
    display: 1
};

export const AuthContext = createContext();

export const checkMinimumRole = (requiredRole, userRole) => {
    const userRoleLevel = ROLE_HIERARCHY[userRole] || 0;
    const requiredRoleLevel = ROLE_HIERARCHY[requiredRole] || 0;
    return userRoleLevel >= requiredRoleLevel;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [id, setId] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    const token = Cookies.get("jwt");
    
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setRole(decoded.role);
        setUsername(decoded.username);
        setId(decoded.id);
        setCompanyName(decoded.companyName);
        setIsAuthenticated(true);
      } catch (err) {
        clearAuth();
      }
    }
    
    setIsLoading(false);
  }, []);

  const clearAuth = () => {
    Cookies.remove("jwt");
    Cookies.remove("refreshToken");
    setIsAuthenticated(false);
    setRole(null);
    setUsername(null);
    setId(null);
    setCompanyName(null);
  };

  const hasMinimumRole = (requiredRole) => {
        return checkMinimumRole(requiredRole, role);
  };

  const login = (data) => {
    const decoded = jwtDecode(data.accessToken);
    setRole(decoded.role);
    setUsername(decoded.username);
    setId(decoded.id);
    setCompanyName(decoded.companyName);
    setIsAuthenticated(true);
  };

  const logout = async () => {
    try {
      const refreshToken = Cookies.get('refreshToken');
      
      if (refreshToken) {
        await axiosInstance.post('/logout', { refreshToken });
      }
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      Cookies.remove("jwt");
      Cookies.remove("refreshToken");
      clearAuth();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        login,
        logout,
        role,
        username,
        userId: id,
        companyName,
        hasMinimumRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
