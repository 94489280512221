import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import LoginPage from "./LoginPage";
import Navbar from "./Navbar";
import PartDetails from "./Tracking/PartDetails";
import StepDetails from "./Tracking/Steps/StepDetails";
import { PartsProvider } from "./Context/PartsContext";
import { AuthProvider } from "./Context/AuthContext";
import { CreateOrderPage } from "./Ordering/CreateOrderPage";
import RegisterPage from "./RegisterPage";
import PrivateRoute from "./PrivateRoute";
import CalendarView from "./Calendar/CalendarView";
import OrderList from "./Tracking/Orders/OrderList";
import OrderDetails from "./Tracking/Orders/OrderDetails";
import PartsPage from "./Tracking/PartsPage";
import DisplayKanban from "./Tracking/DisplayKanban";
import Account from './AccountPage';
import LandingPage from "./Landing/LandingPage";
import RefundPolicy from "./Landing/RefundPolicy";
import VersionChecker from "./VersionChecker";

const App = () => {
    return (
        <AuthProvider>
            <PartsProvider>
                <Navbar />
                <VersionChecker />
                <Routes>
                    <Route path="/landing" element={<LandingPage />} />
                    <Route path="/login" element={
                        <div className="container mt-3">
                            <LoginPage />
                        </div>
                    } />
                    <Route path="/register" element={
                        <div className="container mt-3">
                            <RegisterPage />
                        </div>
                    } />
                    <Route path="/" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <Home />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/part/:partId" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <PartDetails />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/part/:partId/step/:stepId" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <StepDetails />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/create-order" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <CreateOrderPage />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/calendar" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <CalendarView />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/parts" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <PartsPage />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/orders" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <OrderList />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/order/:orderId" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <OrderDetails />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/account" element={
                        <PrivateRoute>
                            <div className="container mt-3">
                                <Account />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/display" element={
                        <PrivateRoute requiredRole="display">
                            <div className="container mt-3">
                                <DisplayKanban />
                            </div>
                        </PrivateRoute>
                    } />
                    <Route path="/refund-policy" element={
                        <div className="container mt-3">
                            <RefundPolicy />
                        </div>
                    } />
                </Routes>
            </PartsProvider>
        </AuthProvider>
    );
};

export default App;
