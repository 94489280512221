import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Form, Spinner } from 'react-bootstrap';
import LoadingButton from '../Buttons/LoadingButton';
import axiosInstance from '../axios';

const calculateSkippedSteps = (templateSteps, parentSteps) => {
    var skippedSteps = templateSteps?.filter(templateStep =>
        !parentSteps.some(
            parentStep => parentStep.id === templateStep.id
        )
    ).map(step => step.id);

    if (!skippedSteps) return new Set();
    return new Set(skippedSteps);
}

const mapTemplateSteps = (templateSteps, parentSteps, skippedSteps) => {
    return templateSteps.map(templateStep => {
        const existingParentStep = parentSteps.find(
            parentStep => parentStep.id === templateStep.id
        );

        if (!existingParentStep || skippedSteps.has(templateStep.id)) {
            return createSkippedStep(templateStep);
        }

        const matchingInstruction = findMatchingInstruction(templateStep, existingParentStep);
        if (!matchingInstruction && isStepSkipped(existingParentStep)) {
            return createSkippedStep(templateStep);
        }

        return createActiveStep(templateStep, existingParentStep, matchingInstruction);
    });
};

const createSkippedStep = (templateStep) => ({
    ...templateStep,
    status: 'in progress',
    comment: null,
    updated_by: null,
    updated_at: null,
    instruction_id: 'skip',
});

const createActiveStep = (templateStep, parentStep, matchingInstruction) => ({
    ...templateStep,
    status: parentStep.status || 'in progress',
    comment: parentStep.comment,
    updated_by: parentStep.updated_by,
    updated_at: parentStep.updated_at,
    instruction_id: matchingInstruction?.id || 'skip',
    instruction: parentStep.instruction,
    available_instructions: templateStep.available_instructions
});

const findMatchingInstruction = (templateStep, parentStep) => {
    // Sometimes there is instruction id mismatch but the description is the same
    // This is not very common since every part should have a matching template
    return templateStep.available_instructions.find(
        instr => instr.id === parentStep.instruction_id
    );
}

const isStepSkipped = (step) =>
    step.instruction === 'N/A' || !step.instruction;

function StepEditModal({
    show,
    onHide,
    steps: parentSteps,
    onSave,
    isLoading: isSaving,
    currentWorkflowId,
    onReady,
}) {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [editedSteps, setEditedSteps] = useState([]);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
    const [skippedSteps, setSkippedSteps] = useState(new Set());
    const [isInitializing, setIsInitializing] = useState(true);

    // Notify parent component of modal's ready state
    useEffect(() => {
        const isReady = !isLoadingTemplates && !isInitializing;
        onReady(isReady);
    }, [isLoadingTemplates, isInitializing, onReady]);

    console.log(editedSteps)
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axiosInstance.get('/workflowTemplates');
                setTemplates(response.data);
                console.log("workflow templates: ", response.data)
                setIsLoadingTemplates(false);
            } catch (error) {
                console.error('Failed to fetch templates:', error);
                onHide();
            }
        };

        // Only fetch if we haven't loaded templates yet
        if (isLoadingTemplates) {
            fetchTemplates();
        }
    }, []);

    useEffect(() => {
        const initializeTemplate = async () => {
            if (!templates.length) return;

            try {
                setIsInitializing(true);

                // If no currentWorkflowId, let user pick a template
                if (!currentWorkflowId) {
                    console.warn('No workflow template ID provided');
                    setIsInitializing(false);
                    return;
                }

                const templateExists = templates.some(t => t.id === currentWorkflowId);
                if (!templateExists) {
                    console.warn('Original workflow template not found - template may have been deleted');
                    // Don't set selectedTemplateId, let user choose
                    setIsInitializing(false);
                    return;
                }

                setSelectedTemplateId(currentWorkflowId);
                await handleTemplateChange({
                    target: { value: currentWorkflowId.toString() }
                });
            } catch (error) {
                console.error('Failed to initialize template:', error);
            } finally {
                setIsInitializing(false);
            }
        };

        initializeTemplate();
    }, [show, templates, currentWorkflowId]);

    useEffect(() => {
        if (parentSteps && templates.length > 0 && selectedTemplateId) {
            const selectedTemplate = templates.find(t => t.id === selectedTemplateId);
            if (!selectedTemplate) return;

            const skipped = calculateSkippedSteps(selectedTemplate.steps, parentSteps);
            setSkippedSteps(skipped);
        }
    }, [parentSteps, templates, selectedTemplateId]);

    const handleTemplateChange = async (e) => {
        const templateId = parseInt(e.target.value);
        const selectedTemplate = templates.find(t => t.id === templateId);

        if (!selectedTemplate) {
            setSelectedTemplateId(null);
            setEditedSteps([]);
            return;
        }

        setSelectedTemplateId(templateId);

        const newSkippedSteps = calculateSkippedSteps(selectedTemplate.steps, parentSteps);
        setSkippedSteps(newSkippedSteps);

        const newSteps = mapTemplateSteps(selectedTemplate.steps, parentSteps, newSkippedSteps);
        setEditedSteps(newSteps);
    };

    const handleInstructionChange = (index, instructionId) => {
        setEditedSteps(prev => prev.map((step, i) => {
            if (i === index) {
                return {
                    ...step,
                    instruction_id: instructionId
                };
            }
            return step;
        }));
    };

    const handleSkipToggle = (stepId) => {
        setSkippedSteps(prev => {
            const newSkipped = new Set(prev);
            const willBeSkipped = !newSkipped.has(stepId);

            if (willBeSkipped) {
                newSkipped.add(stepId);
            } else {
                newSkipped.delete(stepId);
            }

            setEditedSteps(prev => prev.map(step => {
                if (step.id === stepId) {
                    return {
                        ...step,
                        instruction_id: willBeSkipped ? 'skip' : null,
                        instruction: willBeSkipped ? 'N/A' : null
                    };
                }
                return step;
            }));

            return newSkipped;
        });
    };

    const handleSave = () => {
        // Reset statuses only for steps that changed
        const updatedSteps = editedSteps.map(step => {
            const originalStep = parentSteps.find(p => p.id === step.id);
            const wasSkipped = originalStep?.instruction_id === 'skip';
            const isNowSkipped = skippedSteps.has(step.id);

            // Reset status if skip state or instruction changed
            if (wasSkipped !== isNowSkipped ||
                (!isNowSkipped && originalStep?.instruction_id !== step.instruction_id)) {
                return {
                    ...step,
                    status: 'in progress',
                    comment: null,
                    updated_by: null,
                    updated_at: null
                };
            }
            return step;
        });

        onSave({
            workflow_template_id: selectedTemplateId,
            steps: updatedSteps
        });
    };

    const handleClose = () => {
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!currentWorkflowId ?
                        "Select Workflow Template" :
                        (currentWorkflowId && !templates.find(t => t.id === currentWorkflowId)) ?
                            "Original Template Not Found" :
                            "Edit Process Steps"
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    {!currentWorkflowId && (
                        <div className="alert alert-info">
                            Please select a workflow template to continue.
                        </div>
                    )}
                    {currentWorkflowId && !templates.find(t => t.id === currentWorkflowId) && (
                        <div className="alert alert-warning">
                            The original workflow template is no longer available.
                            Please select a new template. Your existing step data will be preserved where possible.
                        </div>
                    )}
                    <Form.Label>Workflow Template</Form.Label>
                    <Form.Select
                        onChange={handleTemplateChange}
                        value={selectedTemplateId || ''}
                    >
                        <option value="">Select a workflow template</option>
                        {templates.map(template => (
                            <option key={template.id} value={template.id}>
                                {template.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                {selectedTemplateId && editedSteps.length > 0 && (
                    <ListGroup>
                        {editedSteps.map((step, index) => (
                            <ListGroup.Item key={step.id}>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div>
                                        <div className="fw-bold">{step.name}</div>
                                        {step.status !== 'in progress' && (
                                            <div className="text-muted small">
                                                {step.status}
                                                {step.updated_by && ` • Last updated by: ${step.updated_by}`}
                                            </div>
                                        )}
                                    </div>
                                    <Form.Check
                                        type="switch"
                                        id={`skip-step-${step.id}`}
                                        label="Skip Step"
                                        checked={skippedSteps.has(step.id)}
                                        onChange={() => handleSkipToggle(step.id)}
                                    />
                                </div>

                                {!skippedSteps.has(step.id) && (
                                    <Form.Group>
                                        <Form.Label className="small">Instructions</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            value={step.instruction_id || ''}
                                            onChange={(e) => handleInstructionChange(index, e.target.value)}
                                            disabled={skippedSteps.has(step.id)}
                                        >
                                            <option value="">Select instruction</option>
                                            {step.available_instructions.map(instruction => (
                                                <option
                                                    key={instruction.id}
                                                    value={instruction.id}
                                                >
                                                    {instruction.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="primary"
                    onClick={handleSave}
                    text="Save Changes"
                    loadingText="Saving..."
                    isLoading={isSaving}
                    disabled={!selectedTemplateId || editedSteps.some(step =>
                        !skippedSteps.has(step.id) && !step.instruction_id
                    )}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default StepEditModal; 