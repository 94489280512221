import { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

function LoadingButton({
    isLoading,
    onClick,
    text,
    loadingText,
    type,
    size = "md",
    variant = "primary",
    className = "",
    disabled = false,
    delay = 400,
}) {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        let timer;
        if (isLoading) {
            // To avoid some spinner flashing on quick requests
            timer = setTimeout(() => {
                setShowSpinner(true);
            }, delay);
        } else {
            clearTimeout(timer);
            setShowSpinner(false);
        }

        return () => clearTimeout(timer);
    }, [isLoading, delay]);

    return (
        <Button
            type={type}
            variant={variant}
            onClick={onClick}
            className={className}
            disabled={isLoading || disabled}
            size={size}
        >
            {showSpinner ? (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />{" "}
                    {loadingText}
                </>
            ) : (
                text
            )}
        </Button>
    );
}

export default LoadingButton;
