import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { formatDate } from "../../utils/formatters";
import apd_logo from './apd_logo.jpg';

const generateLotNumber = (parts, purchaseOrderId, allOrderParts) => {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');

    // Create bitmap of selected parts
    const bitmap = allOrderParts.map(orderPart =>
        parts.some(selectedPart => selectedPart.id === orderPart.id) ? '1' : '0'
    ).join('');

    // Convert bitmap to decimal number
    const decimal = parseInt(bitmap, 2);

    return `${date}-${purchaseOrderId}-${decimal}`;
};

export const generateCoC = async (parts, purchaseOrderId, allOrderParts) => {
    try {
        const pdfDoc = await PDFDocument.create();
        let page = pdfDoc.addPage([612, 792]);

        const logoBytes = await fetch(apd_logo).then(res => res.arrayBuffer());
        const logoImage = await pdfDoc.embedJpg(logoBytes);

        const originalAspectRatio = logoImage.width / logoImage.height;
        const desiredWidth = 150;
        const desiredHeight = desiredWidth / originalAspectRatio;

        page.drawImage(logoImage, {
            x: 50,
            y: 650,
            width: desiredWidth,
            height: desiredHeight,
        });

        const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        const textOptions = { font: helvetica, size: 11, color: rgb(0, 0, 0) };
        const headerOptions = { font: helveticaBold, size: 12, color: rgb(0, 0, 0) };
        const titleOptions = { font: helveticaBold, size: 16, color: rgb(0, 0, 0) };

        page.drawText('Certificate of Conformance (CoC)', {
            x: 250,
            y: 718,
            ...titleOptions
        });

        const drawField = (label, value, yPosition) => {
            page.drawText(label + ':', {
                x: 50,
                y: yPosition,
                ...headerOptions
            });
            page.drawText(value || '', {
                x: 200,
                y: yPosition,
                ...textOptions
            });
        };

        const today = new Date();
        const lotDate = today.toISOString().slice(0, 10).replace(/-/g, '');
        const lotNumber = generateLotNumber(parts, purchaseOrderId, allOrderParts);
        const completionDate = formatDate(today);

        drawField('Customer Name', parts[0].customer_name, 655);
        drawField('Customer Address', parts[0].shipping_address?.split(',')[0], 630);
        page.drawText(parts[0].shipping_address?.split(',').slice(1).join(','), {
            x: 196,
            y: 615,
            ...textOptions
        });
        drawField('Purchase Order #', `${purchaseOrderId}`, 590);
        drawField('MFG Date', completionDate, 565);
        drawField('Lot Number', lotNumber, 540);

        // Draw table headers
        const tableTop = 515;
        const columnWidths = {
            partNumber: 250,
            revision: 100,
            quantity: 100
        };

        // Draw table headers
        page.drawText('Part Number', {
            x: 50,
            y: tableTop,
            ...headerOptions
        });
        page.drawText('Revision', {
            x: 50 + columnWidths.partNumber,
            y: tableTop,
            ...headerOptions
        });
        page.drawText('Quantity', {
            x: 50 + columnWidths.partNumber + columnWidths.revision,
            y: tableTop,
            ...headerOptions
        });

        // Draw horizontal lines for table
        const drawHorizontalLine = (y) => {
            page.drawLine({
                start: { x: 50, y },
                end: { x: 50 + columnWidths.partNumber + columnWidths.revision + columnWidths.quantity, y },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        };

        // Draw vertical lines for table
        const drawVerticalLines = (y1, y2) => {
            let x = 50;
            [columnWidths.partNumber, columnWidths.revision, columnWidths.quantity].forEach(width => {
                page.drawLine({
                    start: { x, y: y1 },
                    end: { x, y: y2 },
                    thickness: 1,
                    color: rgb(0, 0, 0),
                });
                x += width;
            });
            // Draw final vertical line
            page.drawLine({
                start: { x, y: y1 },
                end: { x, y: y2 },
                thickness: 1,
                color: rgb(0, 0, 0),
            });
        };

        // Draw table borders
        drawHorizontalLine(tableTop - 5);  // Header bottom line
        drawHorizontalLine(tableTop + 15); // Header top line

        // Draw rows for each part
        let currentY = tableTop - 25;
        const rowHeight = 20;

        parts.forEach((part, index) => {
            // Add a new page if we're running out of space
            if (currentY < 250) {
                page = pdfDoc.addPage([612, 792]);
                currentY = 700;  // Reset Y position for new page
            }

            page.drawText(part.part_unique_id, {
                x: 55,
                y: currentY,
                ...textOptions
            });
            page.drawText(part.revision || '', {
                x: 55 + columnWidths.partNumber,
                y: currentY,
                ...textOptions
            });
            page.drawText(part.qty_ordered.toString(), {
                x: 55 + columnWidths.partNumber + columnWidths.revision,
                y: currentY,
                ...textOptions
            });

            currentY -= rowHeight;
        });

        // Start certification text with proper spacing
        currentY -= 40;  // Add some padding after the parts table

        // If there isn't enough room for certification and signatures (need ~200 units), add new page
        if (currentY < 250) {
            page = pdfDoc.addPage([612, 792]);
            currentY = 700;
        }

        // Draw certification text with dynamic positioning
        const certificationText = `All technical requirements of the contract PO ${purchaseOrderId} are satisfied. Quality and performance are in accordance with the contract PO ${purchaseOrderId} specifications, and all references and associated contractual drawings and documents.`;

        const words = certificationText.split(' ');
        let line = '';
        currentY = 250;
        const maxWidth = 500;
        const lineHeight = 15;

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const testLine = line + (line ? ' ' : '') + word;
            const testWidth = helvetica.widthOfTextAtSize(testLine, textOptions.size);

            if (testWidth > maxWidth && i > 0) {
                // Draw the current line
                if (line.includes('PO')) {
                    let currentX = 50;
                    const segments = line.split(/(PO \d+)/);

                    for (const segment of segments) {
                        if (segment.startsWith('PO ')) {
                            // Draw PO number in bold
                            page.drawText(segment, {
                                x: currentX,
                                y: currentY,
                                font: helveticaBold,
                                size: 11,
                                color: rgb(0, 0, 0)
                            });
                            currentX += helveticaBold.widthOfTextAtSize(segment, 11);
                        } else if (segment) {
                            // Draw regular text
                            page.drawText(segment, {
                                x: currentX,
                                y: currentY,
                                ...textOptions
                            });
                            currentX += helvetica.widthOfTextAtSize(segment, 11);
                        }
                    }
                } else {
                    // Draw regular text line
                    page.drawText(line, {
                        x: 50,
                        y: currentY,
                        ...textOptions
                    });
                }

                line = word;
                currentY -= lineHeight;
            } else {
                line = testLine;
            }
        }

        // Draw the last line
        if (line) {
            if (line.includes('PO')) {
                let currentX = 50;
                const segments = line.split(/(PO \d+)/);

                for (const segment of segments) {
                    if (segment.startsWith('PO ')) {
                        // Draw PO number in bold
                        page.drawText(segment, {
                            x: currentX,
                            y: currentY,
                            font: helveticaBold,
                            size: 11,
                            color: rgb(0, 0, 0)
                        });
                        currentX += helveticaBold.widthOfTextAtSize(segment, 11);
                    } else if (segment) {
                        // Draw regular text
                        page.drawText(segment, {
                            x: currentX,
                            y: currentY,
                            ...textOptions
                        });
                        currentX += helvetica.widthOfTextAtSize(segment, 11);
                    }
                }
            } else {
                // Draw regular text line
                page.drawText(line, {
                    x: 50,
                    y: currentY,
                    ...textOptions
                });
            }
        }

        // Draw checkboxes and signature lines as before
        const checkboxItems = [
            'All Contractual "Flow Down" Requirements',
            'Supplier Standards Guide',
            'Product Conformance',
            'Free of Counterfeit Material and use of only certified material'
        ];

        // Update checkbox positions to be relative to currentY
        currentY -= 40;  // Space after certification text
        checkboxItems.forEach((item, index) => {
            page.drawRectangle({
                x: 50,
                y: currentY - (index * 20),
                width: 10,
                height: 10,
                borderColor: rgb(0, 0, 0),
                borderWidth: 1
            });

            page.drawText('X', {
                x: 52,
                y: currentY + 1 - (index * 20),
                font: helveticaBold,
                size: 10,
                color: rgb(0, 0, 0)
            });

            page.drawText(item, {
                x: 70,
                y: currentY - (index * 20),
                ...textOptions
            });
        });

        // Update signature section positioning
        currentY -= (checkboxItems.length * 20 + 40);  // Space after checkboxes

        // Signature lines with dynamic positioning
        page.drawLine({
            start: { x: 50, y: currentY },
            end: { x: 200, y: currentY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: 250, y: currentY },
            end: { x: 350, y: currentY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: 400, y: currentY },
            end: { x: 500, y: currentY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        // Labels under lines
        page.drawText('Signature', {
            x: 100,
            y: currentY - 15,
            ...textOptions
        });

        page.drawText('Title', {
            x: 290,
            y: currentY - 15,
            ...textOptions
        });

        page.drawText('Completion Date', {
            x: 420,
            y: currentY - 15,
            ...textOptions
        });

        // Values above lines
        page.drawText('President', {
            x: 270,
            y: currentY + 10,
            ...textOptions
        });

        page.drawText(completionDate, {
            x: 420,
            y: currentY + 10,
            ...textOptions
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `CoC-${purchaseOrderId}-${parts.length}parts.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error generating CoC:', error);
    }
};