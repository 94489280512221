import { useState, useEffect } from 'react';
import { Row, Col, Card, ListGroup, Badge, Spinner, Nav } from "react-bootstrap";
import { formatDate } from "../utils/formatters";
import axiosInstance from '../axios';
import SearchBar from './SearchBar';

function DisplayKanban() {
    const [parts, setParts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [isLoadingParts, setIsLoadingParts] = useState(true);
    const [isLoadingDepts, setIsLoadingDepts] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axiosInstance.get('/departments');
                setDepartments(response.data);
            } catch (err) {
                console.error('Error fetching departments:', err);
                setError('Failed to load departments');
            } finally {
                setIsLoadingDepts(false);
            }
        };

        fetchDepartments();
    }, []);

    useEffect(() => {
        const fetchParts = async () => {
            try {
                const response = await axiosInstance.get('/display/parts');
                setParts(response.data);
            } catch (err) {
                console.error('Error fetching parts:', err);
                setError('Failed to load parts');
            } finally {
                setIsLoadingParts(false);
            }
        };

        fetchParts();
        const interval = setInterval(fetchParts, 30000);
        return () => clearInterval(interval);
    }, []);

    const getDueDateColor = (dueDate) => {
        const today = new Date();
        const due = new Date(dueDate);
        const diffDays = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

        if (diffDays < 0) return 'text-danger';
        if (diffDays <= 7) return 'text-warning';
        return 'text-success';
    };

    const getFilteredParts = () => {
        if (!searchTerm) return parts;

        const searchLower = searchTerm.toLowerCase();
        return parts.filter(part =>
            part.part_unique_id.toLowerCase().includes(searchLower) ||
            part.purchase_order_id.toLowerCase().includes(searchLower)
        );
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const getPartsGroupedByStep = () => {
        const filteredParts = getFilteredParts();
        const grouped = {};

        if (activeTab === 'all') {
            // For 'all' tab, first collect all unique steps
            const uniqueSteps = new Set();
            filteredParts.forEach(part => {
                part.steps.forEach(step => {
                    if (step.status === 'in progress') {
                        uniqueSteps.add(step.name);
                    }
                });
            });

            // Initialize groups with empty arrays
            uniqueSteps.forEach(stepName => {
                grouped[stepName] = [];
            });

            // Group parts by their current in-progress step
            filteredParts.forEach(part => {
                const currentStep = part.steps.find(step => step.status === 'in progress');
                if (currentStep && currentStep.name) {
                    grouped[currentStep.name].push(part);
                }
            });
        } else {
            // For specific department tabs
            const activeDepartment = departments.find(d => d.id === activeTab);
            if (activeDepartment?.steps) {
                activeDepartment.steps.forEach(step => {
                    grouped[step.name] = [];
                });
            }

            filteredParts.forEach(part => {
                const currentStep = part.steps.find(step =>
                    step.status === "in progress" &&
                    step.department_id === activeTab
                );
                if (currentStep) {
                    if (!grouped[currentStep.name]) {
                        grouped[currentStep.name] = [];
                    }
                    grouped[currentStep.name].push(part);
                }
            });
        }

        return grouped;
    };

    // Show loading spinner if either parts or departments are still loading
    if (isLoadingParts || isLoadingDepts) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner animation="border" role="status" variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-danger mt-4">{error}</div>;
    }

    return (
        <div className="container-fluid">
            <div className="mb-4">
                <div className="w-100" style={{ maxWidth: '100%' }}>
                    <SearchBar onSearch={handleSearch} />
                </div>
            </div>

            <Nav
                variant="tabs"
                className="mb-3 border-0"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k === 'all' ? 'all' : parseInt(k))}
            >
                <Nav.Item>
                    <Nav.Link eventKey="all" className="border-bottom-0">All Parts</Nav.Link>
                </Nav.Item>
                {departments.map((dept) => (
                    <Nav.Item key={dept.id}>
                        <Nav.Link eventKey={dept.id} className="border-bottom-0">{dept.name}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>

            <div className="mb-5">
                <Row>
                    {Object.entries(getPartsGroupedByStep()).map(([step, parts]) => (
                        <Col key={step} xs={12} md={6} xl={4} xxl={3} className="mb-3">
                            <Card className="h-100 shadow-sm">
                                <Card.Header className="d-flex justify-content-between align-items-center py-3 bg-light">
                                    <h6 className="mb-0 text-truncate me-2" style={{ maxWidth: '80%' }}>{step}</h6>
                                    <Badge bg="secondary" className="px-3 flex-shrink-0">
                                        {parts.length}
                                    </Badge>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <ListGroup variant="flush"
                                        className="mt-2 px-2"
                                        style={{
                                            height: 'calc(100vh - 350px)',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}
                                    >
                                        {parts.map((part) => (
                                            <ListGroup.Item
                                                key={part.id}
                                                className={`${getDueDateColor(part.due_date)} p-3 mb-2 border border-medium-subtle rounded`}
                                            >
                                                <div className="d-flex justify-content-between align-items-start">
                                                    <div>
                                                        <strong>{part.part_unique_id}</strong>
                                                        <div className="small text-muted">
                                                            Order: {part.purchase_order_id}
                                                        </div>
                                                        <div className={`small ${getDueDateColor(part.due_date)}`}>
                                                            Due: {formatDate(part.due_date)}
                                                        </div>
                                                    </div>
                                                    <div className="text-end small">
                                                        Qty: {part.qty_ordered}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}

export default DisplayKanban; 