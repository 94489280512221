import React, { useState, useContext, useEffect } from "react";
import { PartsContext } from "../Context/PartsContext";
import { Container, Alert, Form, Tabs, Tab, Button } from "react-bootstrap";
import FileUpload from "./FileUpload";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios";
import customerData from "./customers_data.json";
import CreatableSelect from "react-select/creatable";
import CreatePartsGrid from "./CreatePartsGrid";
import { convertLocalMidnightToUtc } from "../utils/formatters";
import ManualPartsEntry from './ManualPartsEntry';
import { AuthContext } from "../Context/AuthContext";

export function CreateOrderPage() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { parts: contextParts, setParts: setContextParts } =
        useContext(PartsContext);
    const [parts, setParts] = useState([]);
    const [orderDetails, setOrderDetails] = useState({
        customer_name: "",
        shipping_address: "",
        buyer_name: "",
        purchase_order_id: "",
    });
    const [templates, setTemplates] = useState([]);
    const { companyName } = useContext(AuthContext);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axiosInstance.get('/workflowTemplates');
                setTemplates(response.data);
            } catch (error) {
                console.error('Failed to fetch workflow templates:', error);
                setError('Failed to load workflow templates');
            }
        };
        fetchTemplates();
    }, []);


    const handleSelectChange = (selectedOption) => {
        if (selectedOption == null) {
            setOrderDetails({
                ...orderDetails,
                customer_name: "",
                shipping_address: "",
            });
            return;
        }

        if (selectedOption.__isNew__) {
            setOrderDetails({
                ...orderDetails,
                customer_name: selectedOption.value,
                shipping_address: "",
            });
        } else {
            setOrderDetails({
                ...orderDetails,
                customer_name: selectedOption.value,
                shipping_address: selectedOption.shipping_address,
            });
        }
    };

    const handleOrderChange = (e) => {
        const { name, value } = e.target;
        setOrderDetails({
            ...orderDetails,
            [name]: value,
        });
    };

    const uploadOrder = async () => {
        const uploadParts = parts.map(part => ({
            ...part,
            steps: part.steps
                .filter(step => step.instruction_id !== 'skip' && step.instruction !== 'skip')
                .map(step => ({
                    id: step.id,
                    index: step.index,
                    instruction_id: step.instruction_id,
                    instruction: step.instruction,
                    status: "in progress"
                })),
            due_date: convertLocalMidnightToUtc(part.due_date).toISOString(),
            workflow_template_id: part.workflow_template_id
        }));

        try {
            const response = await axiosInstance.post("/uploadOrder", {
                ...orderDetails,
                parts: uploadParts,
            });
            return response.data;
        } catch (error) {
            console.error("Failed to upload order:", error);
            throw error;
        }
    };

    const handleInstructionChange = (partIndex, stepIndex, stepId, instructionId, instruction) => {
        setParts(prevParts => {
            const updatedParts = [...prevParts];
            const targetPart = updatedParts[partIndex];

            if (!targetPart) return prevParts;

            const updatedSteps = targetPart.steps.map(step =>
                step.index === stepIndex
                    ? {
                        ...step,
                        instruction_id: instructionId,
                        instruction: instruction
                    }
                    : step
            );

            updatedParts[partIndex] = {
                ...targetPart,
                steps: updatedSteps
            };

            return updatedParts;
        });
    };

    const handleSampleData = () => {
        setParts((prevParts) =>
            prevParts.map((part) => {
                const template = templates.find(t => t.id === part.workflow_template_id);

                return {
                    ...part,
                    steps: part.steps.map((step) => {
                        if (!step.instruction && !step.instruction_id) {
                            const templateStep = template?.steps.find(
                                ts => ts.id === step.id
                            );

                            const selectedInstruction = templateStep?.available_instructions?.[0];

                            return {
                                ...step,
                                instruction_id: selectedInstruction?.id || null,
                                instruction: selectedInstruction?.description || null
                            };
                        }
                        return step;
                    }),
                };
            })
        );
    };


    const validateInstructions = () => {
        const incompleteParts = parts.filter((part) =>
            part.steps.some((step) => !step.instruction_id)
        );
        if (incompleteParts.length > 0) {
            setError(
                `The following parts are incomplete: ${incompleteParts
                    .map((part) => part.name)
                    .join(", ")}`
            );
            return false;
        }
        setError(null);

        if (
            orderDetails.customer_name == undefined ||
            orderDetails.customer_name == "" ||
            orderDetails.shipping_address == undefined ||
            orderDetails.shipping_address == "" ||
            orderDetails.buyer_name == undefined ||
            orderDetails.buyer_name == "" ||
            orderDetails.purchase_order_id == undefined ||
            orderDetails.purchase_order_id == ""
        ) {
            setError("Fill out customer & order details before submitting.");
            return false;
        }
        return true;
    };

    const validateParts = () => {
        if (!parts || parts.length === 0) {
            setError("Please upload at least one part");
            return false;
        }

        // // Validate due dates
        // const invalidDates = parts.filter(p => p.due_date && new Date(p.due_date) < new Date());
        // if (invalidDates.length > 0) {
        //   setError(`Parts with invalid due dates: ${invalidDates.map(p => p.part_unique_id).join(', ')}`);
        //   return false;
        // }

        return true;
    };

    const handleSubmit = async () => {
        if (validateInstructions() && validateParts()) {
            setIsLoading(true);
            try {
                const res = await uploadOrder();
                setContextParts([...contextParts, ...(res.map(part => ({ ...part, due_date: convertLocalMidnightToUtc(part.due_date).toISOString() })))]);
                navigate("/");
            } catch (err) {
                setError(err.message || "Failed to upload order");
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDeletePart = (partIndex) => {
        setParts((prevParts) => prevParts.filter((_, i) => i !== partIndex));
    };

    const handlePartChange = (partIndex, field, value) => {
        setParts(prevParts => prevParts.map((part, idx) =>
            idx === partIndex ? { ...part, [field]: value } : part
        ));
    };

    const handleWorkflowTemplateChange = (partIndex, templateId) => {
        const selectedTemplate = templates.find(t => t.id === parseInt(templateId));

        setParts(prevParts => prevParts.map((part, idx) => {
            if (idx === partIndex) {
                return {
                    ...part,
                    workflow_template_id: selectedTemplate.id,
                    steps: selectedTemplate.steps.map(step => ({
                        id: step.id,
                        name: step.name,
                        index: step.index,
                        instruction_id: null,
                        instruction: null,
                    }))
                };
            }
            return part;
        }));
    };

    return (
        parts && templates && (
            <Container className="py-4">
                <h2 className="mb-4">Create New Order</h2>
                {companyName === "ttd1" && parts.length > 0 && (
                    <Button
                        variant="secondary"
                        onClick={handleSampleData}
                        className="mb-3"
                    >
                        Fill Sample Data
                    </Button>
                )}

                <Form className="mb-4">
                    <Form.Group className="mb-3">
                        <Form.Label>Customer</Form.Label>
                        <CreatableSelect
                            isClearable
                            options={customerData.map(customer => ({
                                value: customer.customer_name,
                                label: customer.customer_name,
                                shipping_address: customer.shipping_address
                            }))}
                            value={orderDetails.customer_name ? {
                                value: orderDetails.customer_name,
                                label: orderDetails.customer_name
                            } : null}
                            onChange={handleSelectChange}
                            placeholder="Select or create a customer"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Shipping Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="shipping_address"
                            value={orderDetails.shipping_address}
                            onChange={handleOrderChange}
                            placeholder="Shipping address will auto-fill"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Buyer Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="buyer_name"
                            value={orderDetails.buyer_name}
                            onChange={handleOrderChange}
                            placeholder="Enter buyer name"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Purchase Order ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="purchase_order_id"
                            value={orderDetails.purchase_order_id}
                            onChange={handleOrderChange}
                            placeholder="Enter purchase order ID"
                        />
                    </Form.Group>
                </Form>

                <h3 className="mt-5">Add Parts to Order</h3>
                <Tabs defaultActiveKey="file" className="mb-3">
                    <Tab eventKey="file" title="Upload File">
                        <FileUpload
                            setParts={setParts}
                            templates={templates}
                        />
                    </Tab>
                    <Tab eventKey="manual" title="Manual Entry">
                        <ManualPartsEntry
                            setParts={setParts}
                            templates={templates}
                        />
                    </Tab>
                </Tabs>

                {error && (
                    <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
                        {error}
                    </Alert>
                )}
                <CreatePartsGrid
                    parts={parts}
                    templates={templates}
                    handleWorkflowTemplateChange={handleWorkflowTemplateChange}
                    handleInstructionChange={handleInstructionChange}
                    handlePartChange={handlePartChange}
                    handleDeletePart={handleDeletePart}
                />

                <div className="mt-4">
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isLoading || parts.length === 0}
                        hidden={parts.length === 0}
                    >
                        {isLoading ? 'Saving...' : 'Create Order'}
                    </Button>
                </div>
            </Container>
        )
    );
}
