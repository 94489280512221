import { useContext, useState } from "react";
import { Card, Form, ListGroupItem, ListGroup } from 'react-bootstrap';
import LoadingButton from '../../Buttons/LoadingButton';
import { formatDateWithTime } from '../../utils/formatters';
import axiosInstance from '../../axios';
import { AuthContext } from '../../Context/AuthContext';

function StepCommentSection({
    step,
    comments = [],
    setComments,
    setErrorMessage,
}) {
    const { username } = useContext(AuthContext);
    const [newComment, setNewComment] = useState("");
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const addComment = async () => {
        if (!newComment.trim()) return;

        setIsLoading(true);
        setErrorMessage("");
        try {
            await axiosInstance.post("/addStepComment", {
                workflow_id: step.workflow_id,
                step_id: step.id,
                part_id: step.part_id,
                step_index: step.index,
                comment: newComment
            });

            setComments([...comments, {
                comment: newComment,
                updated_at: new Date().toISOString(),
                updated_by_name: username
            }]);
            setNewComment("");
            setShowCommentBox(false);
        } catch (error) {
            console.error("Failed to add comment:", error);
            setErrorMessage(error.response?.data || "Failed to add comment. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="mt-4">
            <h5 className="mb-3">Comments</h5>

            <ListGroup variant="flush">
                {comments.map((comment, index) => (
                    <ListGroupItem key={index}>
                        <Card.Text>{comment.comment || "No comment provided"}</Card.Text>
                        <small className="text-muted">
                            By {comment.updated_by_name || "Unknown"}{" "}
                            at {formatDateWithTime(comment.updated_at)}
                        </small>
                    </ListGroupItem>
                ))}
            </ListGroup>

            {!showCommentBox ? (
                <div className="mt-3">
                    <LoadingButton
                        variant="primary"
                        onClick={() => setShowCommentBox(true)}
                        text="Add Comment"
                        className="me-2"
                    />
                </div>
            ) : (
                <div className="mt-3">
                    <Form className="mb-4">
                        <Form.Group controlId="commentBox">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={newComment}
                                disabled={isLoading}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Enter your comment..."
                            />
                        </Form.Group>
                        <div className="mt-2">
                            <LoadingButton
                                variant="primary"
                                onClick={addComment}
                                text="Submit"
                                loadingText="Submitting..."
                                className="me-2"
                                isLoading={isLoading}
                                disabled={isLoading || !newComment.trim()}
                            />
                            <LoadingButton
                                variant="secondary"
                                onClick={() => {
                                    setShowCommentBox(false);
                                    setNewComment("");
                                    setErrorMessage("");
                                }}
                                text="Cancel"
                                className="me-2"
                                disabled={isLoading}
                            />
                        </div>
                    </Form>
                </div>
            )}
        </div>
    );
}

export default StepCommentSection;