import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { AuthContext } from "./Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
    const { isAuthenticated, companyName, role } = useContext(AuthContext);
    const [showHelp, setShowHelp] = useState(false);
    const isDisplayUser = role === 'display';

    const helpContent = (
        <p className="pt-3 px-3">
            Need help? Contact{' '}
            <a
                href="mailto:support@partline.io"
                onClick={(e) => e.stopPropagation()}
                className="text-primary text-decoration-none"
            >
                support@partline.io
            </a>
        </p>
    );

    const handleMouseLeave = (e) => {
        const relatedTarget = e.relatedTarget;
        if (!(relatedTarget instanceof Element)) {
            setShowHelp(false);
            return;
        }

        if (!relatedTarget.closest('#help-popover') &&
            !relatedTarget.closest('.help-icon')) {
            setShowHelp(false);
        }
    };

    return (
        <div className="px-3">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to="/">
                    {companyName || 'PartLine'}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        {!isDisplayUser && (
                            <>
                                <Nav.Link as={Link} to="/orders">Orders</Nav.Link>
                                <Nav.Link as={Link} to="/parts">Parts</Nav.Link>
                                <Nav.Link as={Link} to="/calendar">Calendar</Nav.Link>
                            </>
                        )}
                        <Nav.Link as={Link} to={isAuthenticated ? "/account" : "/login"}>
                            Account
                        </Nav.Link>
                        <div
                            className="position-relative help-icon d-flex align-items-center ms-2"
                            onMouseEnter={() => setShowHelp(true)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                className="text-secondary"
                                style={{ fontSize: '1rem' }}
                            />
                            {showHelp && (
                                <div
                                    className="position-absolute bg-white border rounded"
                                    style={{
                                        top: '100%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 1000
                                    }}
                                    id="help-popover"
                                    onMouseEnter={() => setShowHelp(true)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {helpContent}
                                </div>
                            )}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Navigation;
