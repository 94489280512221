import { useState } from 'react';
import { Form } from 'react-bootstrap';
import LoadingButton from '../../Buttons/LoadingButton';
import FileAttachments from './FileAttachments';
import axiosInstance from '../../axios';

const FILE_UPLOAD_CONSTANTS = {
  MAX_FILES: 10,
  MAX_FILE_SIZE: 10 * 1024 * 1024,
  VALID_TYPES: ['image/jpeg', 'image/png', 'application/pdf'],
};

const ERROR_MESSAGES = {
  TOO_MANY_FILES: `Maximum of ${FILE_UPLOAD_CONSTANTS.MAX_FILES} files can be uploaded at once.`,
  INVALID_TYPE: 'Invalid file type. Only JPG, PNG and PDF files are allowed.',
  FILE_TOO_LARGE: 'File size exceeds the 10MB limit.',
  UPLOAD_FAILED: 'Failed to upload files. Please try again.',
};

function StepFileUploadSection({ partId, step, setErrorMessage, setAttachments }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    const totalFiles = selectedFiles.length + newFiles.length;

    if (totalFiles > FILE_UPLOAD_CONSTANTS.MAX_FILES) {
      setErrorMessage(ERROR_MESSAGES.TOO_MANY_FILES);
      e.target.value = '';
      return;
    }

    if (newFiles.some(file => !FILE_UPLOAD_CONSTANTS.VALID_TYPES.includes(file.type))) {
      setErrorMessage(ERROR_MESSAGES.INVALID_TYPE);
      e.target.value = '';
      return;
    }

    if (newFiles.some(file => file.size > FILE_UPLOAD_CONSTANTS.MAX_FILE_SIZE)) {
      setErrorMessage(ERROR_MESSAGES.FILE_TOO_LARGE);
      e.target.value = '';
      return;
    }

    const dataTransfer = new DataTransfer();
    [...selectedFiles, ...newFiles].forEach(file => dataTransfer.items.add(file));
    e.target.files = dataTransfer.files;

    setSelectedFiles(prev => [...prev, ...newFiles]);
    setErrorMessage('');
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prev => {
      const newFiles = prev.filter((_, i) => i !== index);
      const dataTransfer = new DataTransfer();
      newFiles.forEach(file => dataTransfer.items.add(file));

      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.files = dataTransfer.files;
        if (newFiles.length === 0) fileInput.value = '';
      }
      return newFiles;
    });
  };


  const handleUpload = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('part_id', String(partId));
      formData.append('step_index', String(step.index));
      selectedFiles.forEach(file => formData.append('files', file));

      const response = await axiosInstance.post("/uploadStepFiles", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.attachments) {
        setAttachments(prev => [
          ...response.data.attachments.map(a => ({
            ...a,
            part_id: partId,
            step_index: step.index
          })),
          ...prev
        ]);
      }

      setSelectedFiles([]);
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = '';

    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.UPLOAD_FAILED);
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <div className="mt-3">
      <Form.Group controlId="fileUpload">
        <Form.Label><strong>Upload Files</strong></Form.Label>
        <Form.Control
          type="file"
          multiple
          onChange={handleFileSelect}
          disabled={uploadLoading}
        />
        <FileAttachments
          files={selectedFiles}
          onRemove={handleRemoveFile}
          setErrorMessage={setErrorMessage}
        />
        {selectedFiles.length > 0 && (
          <LoadingButton
            variant="primary"
            onClick={handleUpload}
            text="Upload Files"
            loadingText="Uploading..."
            className="mt-2"
            isLoading={uploadLoading}
            disabled={uploadLoading}
          />
        )}
      </Form.Group>
    </div>
  );
}

export default StepFileUploadSection;