import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./Context/AuthContext";

const PrivateRoute = ({ children, requiredRole }) => {
    const { isAuthenticated, role, hasMinimumRole } = useContext(AuthContext);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // Display role can only access display & account pages
    if (role === 'display' && (window.location.pathname !== '/display' && window.location.pathname !== '/account')) {
        return <Navigate to="/display" />;
    }

    // Check minimum role requirement if specified
    if (requiredRole && !hasMinimumRole(requiredRole)) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
};

export default PrivateRoute;
