import { useContext, useState } from "react";
import { Card, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoadingButton from '../../Buttons/LoadingButton';
import { formatDateWithTime } from '../../utils/formatters';
import { PartsContext } from "../../Context/PartsContext";
import { AuthContext } from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { generateCoC } from '../Orders/CoCGenerator';
import axiosInstance from '../../axios';

function StepStatusSection({
    step,
    part,
    partId,
    isFirstInProgressStep,
    setErrorMessage,
    orderParts
}) {
    const { parts, setParts } = useContext(PartsContext);
    const { username } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState(null);

    const isCoCStep = step?.name.toLowerCase().includes('create certificate of conformance');

    const updateStatus = async (newStatus) => {
        setIsLoading(true);
        setActionType(newStatus);
        setErrorMessage("");
        try {
            const formData = new FormData();
            formData.append('step_index', String(step.index));
            formData.append('part_id', String(partId));
            formData.append('status', newStatus);

            const response = await axiosInstance.post("/updateStepStatus", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            updateStepsLocal(newStatus);
        } catch (error) {
            console.error("Failed to update order:", error);
            setErrorMessage(error.response?.data || "Failed to update the status. Please try again.");
        } finally {
            setIsLoading(false);
            setActionType(null);
        }
    };

    const updateStepsLocal = (newStatus) => {
        const updatedParts = parts.map((p) => {
            if (p.id == partId) {
                return {
                    ...p,
                    steps: p.steps.map((s) =>
                        s.index == step.index
                            ? {
                                ...s,
                                status: newStatus,
                                updated_by: username,
                                updated_at: new Date().toISOString(),
                            }
                            : s
                    ),
                };
            }
            return p;
        });
        setParts(updatedParts);
    };

    const formatInstructions = (instructions) => {
        return instructions?.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    const hasRejectedSteps = (steps, currentStepIndex) => {
        return steps
            .slice(0, currentStepIndex)
            .some((step) => step.status === "rejected");
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {hasRejectedSteps(part.steps, part.steps.indexOf(step))
                ? "Cannot approve/reject step when previous steps are rejected"
                : "You can only approve/reject the first step in progress"}
        </Tooltip>
    );

    const ActionButton = ({ variant, text, onClick, status }) => {
        const disabled = !isFirstInProgressStep || hasRejectedSteps(part.steps, part.steps.indexOf(step));
        const button = (
            <LoadingButton
                variant={variant}
                className="me-2"
                onClick={onClick}
                disabled={disabled || isLoading}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                text={text}
                loadingText={`${text}ing...`}
                isLoading={isLoading && actionType === status}
            />
        );

        if (disabled) {
            return (
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <span className="d-inline-block">{button}</span>
                </OverlayTrigger>
            );
        }

        return button;
    };

    return (
        <div>
            <div className="mb-3">
                <div className="me-2">
                    {formatInstructions(step.instruction)}
                </div>
                {isCoCStep && (
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        className="me-2 mt-2"
                        onClick={() => generateCoC([part], part.purchase_order_id, orderParts)}
                    >
                        <FontAwesomeIcon icon={faFileDownload} className="me-1" />
                        Download CoC
                    </Button>
                )}
            </div>

            {step.status !== "in progress" && (
                <>
                    <Card.Text>
                        <strong>Updated By:</strong> {step.updated_by || "Unknown"}
                    </Card.Text>
                    <Card.Text>
                        <strong>Updated At:</strong> {formatDateWithTime(step.updated_at)}
                    </Card.Text>
                    <LoadingButton
                        variant="outline-secondary"
                        className="me-2"
                        onClick={() => updateStatus("in progress")}
                        text={step.status == 'approved' ? `Un-approve` : `Un-reject`}
                        loadingText={step.status == 'approved' ? `Un-approving...` : `Un-rejecting...`}
                        isLoading={isLoading && actionType === "in progress"}
                    />
                </>
            )}

            {step.status === "in progress" && (
                <div className="mb-4">
                    <ActionButton
                        variant="success"
                        text="Approve"
                        onClick={() => updateStatus("approved")}
                        status="approved"
                    />
                    <ActionButton
                        variant="danger"
                        text="Reject"
                        onClick={() => updateStatus("rejected")}
                        status="rejected"
                    />
                </div>
            )}
        </div>
    );
}

export default StepStatusSection;